import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { createHead } from '@vueuse/head'
import { loadFonts } from './plugins/webfontloader'


const head = createHead();
loadFonts()


createApp(App)
  .use(vuetify)
  .use(head)
  .mount('#app')
