<template>
  <v-footer border class="footer">
    <v-container>
      <v-row>
        <!-- Partita IVA -->
        <v-col cols="12" md="4">
          <h3 class="white--text">Partita IVA</h3>
          <p class="white--text">
            <v-icon color="white" class="mr-2">mdi-account-details</v-icon>{{ partitaIVA }}
          </p>
        </v-col>
        
        <!-- Contatti -->
        <v-col cols="12" md="4">
          <h3 class="white--text">Contatti</h3>
          <p class="white--text">
            <a :href="'mailto:' + contattiEmail" class="white--text" aria-label="Contattaci via email">
              <v-icon color="white" class="mr-2">mdi-email</v-icon>{{ contatti }}
            </a>
          </p>
          <p class="white--text">
            <a :href="'tel:' + telefonoNumero" class="white--text" aria-label="Chiamaci">
              <v-icon color="white" class="mr-2">mdi-phone</v-icon>{{ telefono }}
            </a>
          </p>
        </v-col>
        
        <!-- Sede legale -->
        <v-col cols="12" md="4">
          <h3 class="white--text">Sede legale</h3>
          <address class="white--text">
            <p>Via via Stanco di Sopra 91/A</p>
            <p>40030 Grizzana Morandi (BO)</p>
          </address>
        </v-col>
      </v-row>

      <!-- Diritti d'autore -->
      <v-row>
        <v-col cols="12" class="text-center">
          <p class="white--text">&copy; {{ currentYear }} {{ creatorName }}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterPage',
  data() {
    return {
      creatorName: 'redlight di rossi luca',
      partitaIVA: '04210691202',
      contatti: 'Email: info@redlightrossiluca.com',
      telefono: 'Telefono: +39 3385685118',
      contattiEmail: 'info@redlightrossiluca.com',
      telefonoNumero: '+393385685118',
      currentYear: new Date().getFullYear()
    };
  }
};
</script>

<style scoped>
.footer {
  width: 100%;
  height: 200px; /* Altezza ridotta */
  background-color: #B9A3B1; /* Colore più caldo */
  margin-top: 20px; /* Margine superiore */
}

.footer > .v-container {
  max-width: 1200px; /* Larghezza massima del contenuto del footer */
  margin: 0 auto; /* Centra il contenuto orizzontalmente */
}

a {
  text-decoration: none;
}
</style>
