<template>
  <v-container>
    <h1 class="text-center">Progetti Aziendali</h1>
    <p class="text-center" id="description">Esplora i progetti innovativi realizzati dalla nostra azienda, con soluzioni tecnologiche all'avanguardia.</p>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto my-4" max-width="100%">
          <v-img
            class="responsive-img"
            src="@/assets/progetti.jpg"
            cover
            alt="Immagine dei progetti aziendali in corso"
            aria-describedby="description"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProgettiAzienda",
};
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
}

.responsive-img {
  width: 100%; /* Assicura che l'immagine si adatti alla larghezza del contenitore */
  height: auto; /* Mantiene le proporzioni dell'immagine */
  max-height: 600px; /* Limita l'altezza massima per schermi più grandi */
}

/* Stili per il testo descrittivo */
#description {
  margin-top: 10px;
  font-size: 1.1rem;
  color: #444;
}
</style>
