<template>
  <div class="gallery">
    <!-- Se è in corso il caricamento, mostriamo un messaggio -->
    <div v-if="loading" class="loading">
      <p>Caricamento in corso...</p>
    </div>

    <!-- Mostriamo un messaggio se non ci sono media -->
    <div v-if="!loading && items.length === 0" class="no-media">
      <p>Nessun contenuto disponibile</p>
    </div>

    <!-- Grid delle immagini e video -->
    <div v-if="!loading && items.length > 0" class="media-grid">
      <figure v-for="item in items" :key="item.url" class="media-item">
        <!-- Mostriamo immagini -->
        <img v-if="isImage(item.url)" :src="item.url" :alt="item.description" class="media-img" :aria-label="item.description"/>

        <!-- Mostriamo video senza controlli -->
        <video v-else autoplay loop muted class="media-video" :aria-label="item.description">
          <source :src="item.url" type="video/mp4">
          Il tuo browser non supporta il tag video.
        </video>

        <!-- Descrizione sotto ogni elemento -->
        <figcaption class="description" :aria-label="'Descrizione: ' + item.description">{{ item.description }}</figcaption>
      </figure>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      items: [], // Array che conterrà immagini e video
      loading: true // Stato di caricamento
    };
  },
  methods: {
    // Funzione per recuperare immagini e video dalle API Flask
    async fetchMedia() {
      try {
        const response = await axios.get('https://galleriafotoredlight.onrender.com/media');
        console.log("Dati ricevuti dalle API:", response.data); // Aggiunto per il debug
        this.items = response.data;
      } catch (error) {
        console.error("Errore nel recuperare i media:", error);
      } finally {
        this.loading = false; // Fine del caricamento
      }
    },
    // Funzione per determinare se l'URL è un'immagine
    isImage(url) {
      return url.match(/\.(jpeg|jpg|gif|png)$/) !== null;
    }
  },
  // Recuperiamo i media appena il componente è montato
  mounted() {
    this.fetchMedia();
  }
};
</script>

<style scoped>
.gallery {
  padding: 20px;
  text-align: center;
}

.loading {
  font-size: 18px;
}

.no-media {
  font-size: 18px;
}

.media-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Mostra 3 colonne fisse */
  gap: 20px;
  margin-top: 20px;
}

.media-item {
  position: relative;
  overflow: hidden; /* Nasconde eventuali parti in eccesso */
}

.media-img,
.media-video {
  width: 100%;
  height: 200px; /* Altezza fissa per immagini e video */
  object-fit: cover; /* Mantiene le proporzioni */
}

.description {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}
</style>
