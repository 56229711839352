<template>
  <v-app>
    <LodingAnimation></LodingAnimation>
    <v-main>
      <LogoAzzienda></LogoAzzienda>
      <v-container>
        <v-app-bar dark name="app-bar" flat>
          <h1 class="mx-auto">REDLIGHT</h1>
        </v-app-bar>

        <MainCard></MainCard>
        <AzziendaFiturs></AzziendaFiturs>
        <NavBar></NavBar>
        <ProgettiAzzienda></ProgettiAzzienda>
        
        <!-- Collegamento alla galleria -->
        <v-row align="center" justify="center" class="my-5">
          <v-btn
            large
            block
            color="primary"
            elevation="2"
            @click="showGallery = !showGallery"
            class="google-btn"
          >
            Galleria lavori
          </v-btn>
          
          <div v-if="showGallery">
            <GalleriaComponent/>
          </div>
        </v-row>

      </v-container>
      <RouterView></RouterView>
    </v-main>
    <FouterPage></FouterPage>
  </v-app>
</template>

<script>
import { useHead } from '@vueuse/head';
import LodingAnimation from "./components/LodingAnimation.vue";
import MainCard from "./components/MainCard.vue";
import LogoAzzienda from "./components/LogoAzzienda.vue";
import AzziendaFiturs from "./components/AzziendaFiturs.vue";
import NavBar from "./components/NavBar.vue";
import ProgettiAzzienda from "./components/ProgettiAzzienda.vue";
import FouterPage from "./components/Fouter.vue";
import GalleriaComponent from "./components/GalleriaComponent.vue";

export default {
  name: 'App',
  components: {
    MainCard,
    LogoAzzienda,
    AzziendaFiturs,
    NavBar,
    ProgettiAzzienda,
    FouterPage,
    LodingAnimation,
    GalleriaComponent
  },
  data() {
    return {
      drawerOpen: true, // Drawer aperto di default
      showGallery: false // Variabile per gestire la visibilità della galleria
    }
  },
  setup() {
    // Aggiungi i meta tag usando @vueuse/head
    useHead({
      title: 'Rossi Luca | RedLight',
      meta: [
        {
          name: 'description',
          content: 'Servizi di elettricista a Bologna: impianti elettrici, manutenzione e riparazioni rapide. Contattaci per un preventivo gratuito.'
        },
        {
          name: 'keywords',
          content: 'elettricista Bologna, impianti elettrici, manutenzione elettrica, riparazioni elettriche'
        },
        {
          property: 'og:title',
          content: 'Elettricista Bologna | RedLight'
        },
        {
          property: 'og:description',
          content: 'Servizi di elettricista a Bologna: impianti elettrici, manutenzione e riparazioni rapide. Contattaci per un preventivo gratuito.'
        },
        {
          property: 'og:url',
          content: 'https://www.redlightrossiluca.com'
        }
      ]
    });
  }
};
</script>

<style scoped>
.v-main {
  background-color: #d4e2b4; /* Sfondo con toni freddi e pastellati */
}

.google-btn {
  font-size: 24px; /* Ingrandisci ulteriormente il testo del pulsante */
  padding: 20px 40px; /* Aggiungi più spazio interno */
  border-radius: 15px; /* Arrotonda gli angoli */
}
</style>
